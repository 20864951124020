.social-icon {
  margin: 50px 0;
  font-size: 1.5em;
  transition: 1s;
  cursor: pointer;
}

.social-icon:hover {
  transition: 1s;
  color: #375a7f;
}
