@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap&family=Monoton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;900&display=swap");

:root {
  --discover-red: rgb(192, 33, 29);
}

* {
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none; /* Chrome scroll */
}

body {
  font-size: 16px;
  font-family: "Barlow Semi Condensed";
  /* background-image: url("../build/img/testBackground4.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  -ms-overflow-style: none; /* for Internet Explorer, Edge scroll */
  scrollbar-width: none; /* for Firefox scroll */
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  display: none; /* Chrome scroll */
}

h1 {
  font-family: "Barlow Semi Condensed";
  font-weight: 600 !important;
  font-size: 80px !important;
  text-transform: uppercase;
  text-align: left;
  overflow-wrap: break-word;
}

p,
ol,
.form-label,
.form-check-label {
  font-family: "Roboto";
  font-weight: 100;
  font-size: 1.2em;
  /* word-spacing: 0.2em; */
}

.form-label,
.form-check-label {
  font-size: 0.8em;
  font-weight: 300;
}

h2 {
  font-weight: 800;
  text-transform: uppercase;
  font-family: "Barlow Semi Condensed";
  text-align: left;
  opacity: "0.25";
}

h3 {
  text-transform: uppercase;
  /* font-family: "Roboto"; */
}

a {
  text-decoration: none !important;
}

.show {
  /* background-color: #333;
  border-color: #111;
  width: 100%;
  left: 0;
  
  z-index: 2;
  top: 60px;
  border-radius: 10px; */
  transition: none;
}

.collapsing {
  -webkit-transition: none;
  transition: none !important;
  display: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.85);
}

#the-cube {
  position: fixed;
  bottom: 0;
  left: 50vw;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  text-align: center;
  transform: translate(-50%);
}
