.release-item {
  padding: 50px 0;
  display: flex;
  gap: 5px;
}

.release-image {
  width: 160px;
}

.release-details {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
}

.release-cat-group {
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 0;
}

.release-title {
  text-transform: uppercase;
  font-weight: 800;
}

.release-artist {
  text-transform: capitalize;
  font-weight: 800;
}

.release-tracklisting {
  padding-top: 20px;
}

.admin-control-group {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.release-cat:hover,
.release-label:hover {
  color: #375a7f !important;
}
